import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import jwtDecode, { JwtPayload } from "jwt-decode";
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { userDataType } from '../common/type-definitions';

@Injectable({
  providedIn: 'root'
})
export class BackendHttpClientService {
  private base_url: string;
  private is_bootstrap_authorization_check: boolean = true;

  constructor(private httpClient: HttpClient, private alertController: AlertController, private translate: TranslateService) {
    this.base_url = environment.backedConfigs.base_url.replace(/\/+$/, '');
  }

  private makeRequest(
      method: string,
      url: string,
      queryParams?: {[param: string]: string | number | boolean},
      body?: Object,
      headers?: {[header: string]: string}): Observable<Object | null> {
    if (!this.checkInternetConnection())
      return throwError(new Error('No internet connection!'));
    const requestOptions: {[option: string]: any} = {
      params: queryParams,
      headers: {
        "Accept": "application/json;charset=UTF-8",
        "Content-Type": "application/json;charset=UTF-8",
        ...(headers || {})
      },
      withCredentials: false
    };
    const storedUserData: userDataType = JSON.parse(localStorage.getItem('userData')) as userDataType;
    if (storedUserData) {
      requestOptions.headers.Authorization = 'Bearer ' + storedUserData.jwt;
      //requestOptions.withCredentials = true;
    }
    url = this.base_url + '/' + url.replace(/^\/+/, '');
    let observable: Observable<Object | null>;
    switch(method) {
      case 'POST':
        observable = this.httpClient.post(url, body || {}, requestOptions);
        break;
      case 'PUT':
        observable = this.httpClient.put(url, body || {}, requestOptions);
        break;
      case 'DELETE':
        observable = this.httpClient.delete(url, requestOptions);
        break;
      default: // GET
        observable = this.httpClient.get(url, requestOptions)
        break;
    }
    return observable.pipe(catchError((error, caught) => {
      //console.error(error);
      this.translate.get(['HTTP_ERROR_ALERT_TITLE', 'HTTP_ERROR_ALERT_MESSAGE', 'BUTTONS.OK']).subscribe((vals) => {
        this.alertController
          .create({
            header: vals['HTTP_ERROR_ALERT_TITLE'],
            message: vals['HTTP_ERROR_ALERT_MESSAGE'],
            buttons: [vals['BUTTONS.OK']]
          })
          .then(alert => alert.present());
      });
      throw error;
    }));
  }

  public checkInternetConnection(): boolean {
    if (navigator.onLine)
      return true;
    this.translate.get(['CONNECTION_ERROR_ALERT_TITLE', 'CONNECTION_ERROR_ALERT_MESSAGE', 'BUTTONS.OK']).subscribe(async (vals) => {
      const alert = await this.alertController.create({
        header: vals['CONNECTION_ERROR_ALERT_TITLE'],
        message: vals['CONNECTION_ERROR_ALERT_MESSAGE'],
        buttons: [vals['BUTTONS.OK']]
      });
      await alert.present();
    });
    return false;
  }

  public del(
      url: string,
      queryParams?: {[param: string]: string | number | boolean},
      headers?: {[header: string]: string}): Observable<Object | null> {
    return this.makeRequest('DELETE', url, queryParams, undefined, headers);
  }

  public get(
      url: string,
      queryParams?: {[param: string]: string | number | boolean},
      headers?: {[header: string]: string}): Observable<Object | null> {
    return this.makeRequest('GET', url, queryParams, undefined, headers);
  }

  public post(
      url: string,
      queryParams?: {[param: string]: string | number | boolean},
      body?: Object,
      headers?: {[header: string]: string}): Observable<Object | null> {
    return this.makeRequest('POST', url, queryParams, body, headers);
  }

  public put(
      url: string,
      queryParams?: {[param: string]: string | number | boolean},
      body?: Object,
      headers?: {[header: string]: string}): Observable<Object | null> {
    return this.makeRequest('PUT', url, queryParams, body, headers);
  }

  private _login(authData: { user: string, password: string }): Observable<boolean> {
    localStorage.removeItem('userData'); // Va fatto!!!
    return this
      .post('auth/login', undefined, authData)
      .pipe(map((data: { success: boolean, userData: userDataType }) => {
        if (data && data.success) {
          localStorage.setItem('userData', JSON.stringify(data.userData));
          return true;
        }
        return false;
      }));
  }

  public login(authData: { user: string, password: string }): Observable<boolean> {
    localStorage.setItem('authData', JSON.stringify(authData));
    return this._login(authData);
  }

  public reLogin(): Observable<boolean> {
    const storedAuthData = localStorage.getItem('authData');
    if (typeof storedAuthData === 'string' && this._login(JSON.parse(storedAuthData)))
      return this._login(JSON.parse(storedAuthData));
    return of(false);
  }

  public logout(): boolean {
    localStorage.removeItem('userData');
    localStorage.removeItem('authData');
    return true;
  }

  private validateJwt(jwt: string): boolean {
    let valid: boolean = false;
    try {
      const decoded = jwtDecode<JwtPayload>(jwt);
      valid = !(decoded.exp && (decoded.exp * 1000) < (new Date()).valueOf());
    } catch(error) {}
    return valid;
  }

  public getUserData(): userDataType | null {
    const storedUserData: userDataType = JSON.parse(localStorage.getItem('userData')) as userDataType;
    return storedUserData && this.validateJwt(storedUserData.jwt) ? storedUserData : null;
  }

  public isAuthenticated(): Observable<boolean> {
    const storedUserData: userDataType = JSON.parse(localStorage.getItem('userData')) as userDataType;
    if (storedUserData && this.validateJwt(storedUserData.jwt)) {
      if (!this.is_bootstrap_authorization_check) {
        return of(true);
      } else {
        this.is_bootstrap_authorization_check = false;
        return this
          .get('auth/islogged')
          .pipe(map((data: { success: boolean }) => {
            if (data && data.success)
              return true;
            localStorage.removeItem('userData');
            return false;
          }));
      }
    }
    localStorage.removeItem('userData');
    return of(false);
  }
}
