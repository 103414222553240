import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { BackendHttpClientService } from './backend-http-client.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private backendClient: BackendHttpClientService, private router: Router) {

  }

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.backendClient.isAuthenticated()
        .pipe(tap(success => {
          if (!success)
            this.router.navigateByUrl('/login', { replaceUrl: true });
        }));
  }
}
